.partners-info-section {
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  justify-content: space-around;
}

.partners-info-header {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
}

.partners-info-title {
  width: 100%;
  font-size: 2rem;
  text-align: center;
}
.partners-info-title em {
  color: var(--focused-dark);
  font-family: var(--header-font);
  font-size: 1.25em;
}

.partners-info-decription {
  font-size: 1.25rem;
}

.partners-info-body {
  width: 100%;
  padding: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  justify-content: space-around;
}

.partner-info-container {
  width: 100%;
  max-width: 310px;
  background-color: var(--focused-white);
  border-radius: var(--lg-border-radius);
  padding: 3.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.partner-info-title {
  font-size: 2rem;
  font-family: var(--header-font);
  color: var(--focused-dark);
  font-weight: 300;
  padding-bottom: 1.5rem;
}

.partner-info-description {
  width: 80%;
  line-height: 1.5rem;
  text-align: justify;
}

.partner-cta {
  margin-bottom: 2rem;
}

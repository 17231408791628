.contact-body {
  width: 90%;
  max-width: var(--md-container-width);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.body-column {
  flex-direction: column;
}

.contact-info {
  width: 45%;
  max-width: 450px;
  min-width: 315px;
  margin: 1rem 0;
}

.contact-map {
  border-radius: var(--lg-border-radius);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 30%);
}

.contact-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem 0;
}

.contact-item {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid var(--focused-black);
  padding: 1rem 0;
}

.contact-item p {
  margin: 0;
  font-weight: 300;
}

.contact-item h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: var(--header-font);
  font-size: 1.125rem;
}

.contact-item:first-child {
  border: none;
}

.supplamentary-page-header {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 80, 20%),
      rgba(0, 0, 80, 20%)
    ),
    url("../../assets/img/background-images/Trianglified1.svg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding: 2rem 0;
}
.supplamentary-page-header-container {
  width: 80%;
  max-width: var(--max-container-width);
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  align-items: center;
  justify-content: space-around;
  color: var(--focused-white);
}
.supplamentary-page-title {
  font-size: 3rem;
  text-align: center;
}
.supplamentary-page-description {
  background-image: linear-gradient(to right, white, var(--focused-white));
  color: transparent;
  background-clip: text;
  font-size: 1.25rem;
  font-weight: 350;
  text-align: justify;
}

.supplamentary-body {
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    var(--focused-peach),
    var(--focused-lavender)
  );
  background-attachment: fixed;
  padding: 2rem 0;
}

.supplamentary-body-container {
  width: 90%;
  max-width: var(--max-container-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
}

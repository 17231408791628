.certs {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--focused-white);
}
.certs-container {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
  padding: 1rem 0;
  justify-content: space-around;
}

.cert-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

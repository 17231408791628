.hero-form-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  margin-top: 2rem;
  background: linear-gradient(
      45deg,
      rgba(247, 233, 226, 0.7),
      #fbfafd00,
      rgba(218, 222, 241, 0.7)
    ),
    linear-gradient(
      -45deg,
      rgba(218, 222, 241, 0.7),
      #fbfafd00,
      rgba(247, 233, 226, 0.7)
    );
  border: 1px solid #4e599e33;
  box-shadow: 3px 3px 5px #4e599e33, -3px -3px 5px #4e599e33;
  border-radius: var(--lg-border-radius);
  padding: 2rem;
}

.intro-form-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  color: var(--focused-dark);
}
.intro-form-title h2 {
  text-align: center;
  font-weight: 400;
}

.hero-form {
  width: 100%;
}

.form-submit {
  display: flex;
  justify-content: center;
}

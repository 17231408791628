.legal-info {
  padding: 1.5rem 0;
}

.legal-title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.inline-link {
  color: var(--focused-dark);
  font-weight: 400;
}

.page-not-found {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.page-not-found h2 {
  font-size: 2.5rem;
}

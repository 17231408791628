.application-form-section {
  width: 100%;
}

.application-form-container {
  width: 90%;
  max-width: 500px;
  margin: auto;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  text-align: center;
}

.application-header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
}
.application-heading {
  font-size: 2.5rem;
}
.papplication-form-body {
  width: 100%;
}

.application-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
}

.form-section {
  width: 100%;
}

.form-section-header {
  font-size: 1.5rem;
  padding-bottom: 1.5rem;
  text-align: center;
}
.application-form .form-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.application-form input,
.application-form select {
  width: 50%;
}

input:invalid .form-control {
  border-color: red;
}
.submit-button {
  margin: 0 auto 2rem auto;
}

.submit-section p {
  font-size: 0.875rem;
}

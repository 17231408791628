.submission-thanks {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  justify-content: center;
}
.submission-thanks h2 {
  font-size: 2.5rem;
}

.submission-thanks h3 {
  font-size: 1.5rem;
}

.industry-card {
  width: 150px;
  height: 150px;
  display: flex;
  padding-top: 0.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: var(--md-border-radius);
  background-color: var(--focused-white);
  color: var(--focused-dark);
}

.industry-name-container {
  padding: 0.5rem;
}
.industry-name-container,
h4 {
  text-align: center;
}

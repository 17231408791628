.page-section {
  width: 100%;

  display: flex;
  justify-content: center;
}

.programs--section {
  min-height: 700px;
  background-image: linear-gradient(
    to bottom,
    var(--focused-peach),
    var(--focused-lavender)
  );
  background-attachment: fixed;
}

.programs--section header h2 {
  font-size: 2.75rem;
  font-weight: 300;
}

.programs--section header h2 em {
  font-family: var(--header-font);
  font-size: 1.25em;
  color: var(--focused-dark);
}

.programs--section header p {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  text-align: justify;
}

.button-container {
  position: relative;
  top: -1rem;
}

.industries--section {
  min-height: 500px;
  background-color: var(--focused-dark);
  color: var(--focused-white);
}

.industries--section header h2 {
  font-family: var(--header-font);
  font-size: 3.2rem;
  font-weight: 300;
}
.industries--section header p {
  text-align: justify;
  line-height: 1.4;
}

.section-container {
  width: 85%;
  min-height: 100%;
  padding: 2rem 0;
  display: flex;
}

.industries--section .section-container {
  width: 95%;
  max-width: var(--max-container-width);
}
.section--flow--column {
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  justify-content: space-evenly;
}
.section--flow--row {
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.section-content {
  display: flex;
}
.section--flow--column .section-content {
  width: 90%;
}

.section--flow--row .section-content {
  width: 50%;
  height: 100%;
}
.header--flow--row {
  flex-direction: row;
  justify-content: space-evenly;
}

.header--flow--row * {
  width: 45%;
}

.header--flow--column {
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  justify-content: center;
}
.header--flow--column h2 {
  text-align: center;
}

.body--flow--row {
  flex-direction: row;
  column-gap: 1rem;
  justify-content: space-evenly;
}
.industries--section .body--flow--row {
  width: 45%;
}
.body--flow--column {
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  justify-content: space-evenly;
}

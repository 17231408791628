:root {
  --primary: var(--focused-white);
}

.btn {
  padding: 8px 20px;
  border-radius: var(--sm-border-radius);
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn--primary {
  background-color: var(--primary);
  color: var(--focused-dark);
  border: 1px solid var(--primary);
  transition: all 150ms ease-in;
}

.btn--primary:hover {
  background-color: transparent;
  color: var(--focused-white);
}
.btn--dark {
  background-color: var(--focused-dark);
  color: var(--focused-white);
  border: 1px solid var(--focused-dark);
}
.btn--dark:hover {
  background-color: var(--primary);
  color: var(--focused-dark);
  font-weight: 400;
  transition: all 150ms ease-in;
}

.btn--outline {
  background-color: transparent;
  color: var(--focused-dark);
  border: 1px solid var(--focused-dark);
  transition: all 0.3s ease-out;
}

.btn--outline:hover {
  background-color: var(--focused-dark);
  color: var(--focused-white);
  border: 1px solid var(--focused-dark);
}

.btn--small {
  padding: 6px 15px;
  font-size: 1rem;
}

.btn--medium {
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--mobile {
  text-align: center;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1.5rem;
  background-color: transparent;
  color: var(--focused-dark);
  padding: 14px 20px;
  border: 1px solid var(--focused-dark);
  transition: all 0.3s ease-in-out;
}

.btn--wide {
  padding: 12px 64px;
  font-size: 20px;
}

.primary {
  background-color: var(--focused-black);
  color: var(--focused-white);
  border: none;
}
.primary:hover {
  background-color: var(--focused-white);
  color: var(--focused-dark);
  border: none;
}

.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
}

.hover-arrow {
  position: relative;
  top: 1px;
  margin-left: 10px;
  stroke-width: 1px;
  fill: none;
  stroke: currentColor;
}

.hover-arrow__line-path {
  opacity: 0;
  transition: opacity 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hover-arrow__tip-path {
  transform: none;
  transition: transform 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.btn:hover .hover-arrow__line-path {
  opacity: 100;
}

.btn:hover .hover-arrow__tip-path {
  transform: translateX(3px);
}

.download {
  column-gap: 1rem;
}

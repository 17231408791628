.hero {
  width: 100%;
  overflow: visible;
  display: flex;
  justify-content: center;
  min-height: 50vh;
}

.hero--homepage {
  background: linear-gradient(to bottom, var(--focused-white), #fbfafd00),
    url("./Hero-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (min-width: 780px) {
  .hero--homepage {
    background: linear-gradient(to right, var(--focused-white), #fbfafd00),
      url("./Hero-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hero-container {
  width: 100%;
  padding-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
}

.hero-section {
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-header {
  width: 80%;
  color: var(--focused-white);
  max-width: 400px;
  row-gap: 2rem;
}

.hero-lead-in h1 {
  color: var(--focused-dark);
  font-size: 3rem;
  font-weight: 300;
}

.hero-lead-in em {
  font-family: var(--header-font);
  font-size: 1.3em;
  font-weight: 500;
}

.intro-text {
  width: 95%;
}
.intro-text h3 {
  font-weight: 300;
}

.program-info-section {
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.program-info-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.program-info-header h3 {
  color: var(--focused-dark);
  font-size: 2rem;
  text-align: center;
}

@media screen and (min-width: 1150px) {
  .program-info-header h3 {
    font-size: 2.5rem;
    margin: 2rem 0;
  }
}

.program-info-body {
  width: 100%;
  display: flex;
  column-gap: 2rem;
  align-items: center;
  justify-content: space-around;
}

@media screen and (min-width: 1150px) {
  .program-info-body {
    padding: 2rem;
  }
}

.program-info-section:nth-child(2n) .program-info-body {
  flex-direction: row-reverse;
}
.program-image {
  display: none;
}
@media screen and (min-width: 1150px) {
  .program-image {
    display: block;
    position: relative;
    z-index: 0;
    left: 40px;
  }
}
.program-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
@media screen and (min-width: 1150px) {
  .program-info {
    width: 50%;
  }
}

.program-detail-container {
  width: 100%;
  margin-bottom: 1rem;
}

@media screen and (min-width: 1150px) {
  .program-detail-container {
    margin: 2rem;
  }
}

.program-description {
  width: 100%;
  max-width: 754px;
  margin: 0 auto;
  position: relative;
  z-index: 99;
  text-align: justify;
  padding: 1rem;
}

@media screen and (min-width: 1150px) {
  .program-description {
    width: auto;
    margin: none;
    right: 40px;
    background-color: var(--focused-white);
  }

  .program-info-section:nth-child(2n) .program-image {
    left: -40px;
  }

  .program-info-section:nth-child(2n) .program-description {
    right: -40px;
  }
}

.program-terms-list {
  width: 70%;
  min-width: 275px;
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  list-style: none;
}

.program-term-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.program-term-label {
  justify-self: start;
  color: var(--focused-dark);
  font-weight: 500;
}
.program-term-detail {
  justify-self: end;
}

@media screen and (min-width: 1150px) {
  .program-term-label {
    padding-left: 1rem;
    justify-self: start;
  }
  .program-term-detail {
    justify-self: start;
  }
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-VariableFont_wght.ttf");
  font-weight: 1 999;
}
@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Italic-VariableFont_wght.ttf");
  font-weight: 1 999;
  font-style: italic;
}
@font-face {
  font-family: "EB Garamond";
  src: url("./fonts/EBGaramond-VariableFont_wght.ttf");
  font-weight: 1 999;
}
@font-face {
  font-family: "EB Garamond";
  src: url("./fonts/EBGaramond-Italic-VariableFont_wght.ttf");
  font-weight: 1 999;
  font-style: italic;
}
:root {
  --focused-dark: #2d6091;
  --focused-light: #9fc1f8;
  --focused-white: #fbfafd;
  --focused-black: #1d1d1d;
  --focused-peach: #f7e9e2;
  --focused-lavender: #dadef1;
  --navbar-height: 80px;
  --md-size: 760px;
  --header-font: "EB Garamond", serif;
  --body-font: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --lg-border-radius: 10px;
  --md-border-radius: 7px;
  --sm-border-radius: 4px;
  --max-container-width: 1350px;
  --md-container-width: 1000px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--body-font);
  font-weight: 300;
}

body {
  background-color: var(--focused-white);
}
a {
  text-decoration: none;
  color: var(--focused-dark);
}

.fixed-p2v-gradient {
  background-image: linear-gradient(
    to bottom,
    var(--focused-peach),
    var(--focused-lavender)
  );
  background-attachment: fixed;
}

.app-wrapper {
  max-width: 1700px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(70, 70, 70, 50);
}

.program-card {
  width: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-image-container {
  width: 100%;
}

.card-image {
  border-radius: var(--md-border-radius);
}
.card-title-container {
  width: 95%;
  height: 3rem;
  position: relative;
  top: -2rem;
  background-color: var(--focused-white);
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: var(--sm-border-radius);
}

.card-title-content {
  position: relative;
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  top: 0;
  transition: all 0.2s linear;
  color: var(--focused-black);
}

.card-title-content * {
  margin: 0;
  padding: 0;
  line-height: 2rem;
  text-align: center;
  font-weight: 400;
}

.program-card:hover {
  cursor: pointer;
}

.program-card:hover .card-title-content {
  transition: all 0.2s linear;
  top: -2.5rem;
}
.scrolled-learn-more {
  color: var(--focused-dark);
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
}

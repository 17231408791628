.resources-section {
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  justify-content: space-around;
}

.resources-header {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
}

.resources-title {
  width: 100%;
  font-size: 2rem;
  text-align: center;
  word-spacing: 0.5rem;
}
.resources-title em {
  color: var(--focused-dark);
  font-family: var(--header-font);
}

.resources-description {
  font-size: 1.25rem;
}

.resources-body {
  width: 100%;
}

.resource-item-container {
  width: 100%;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.resource-item-title {
  font-size: 2rem;
  font-family: var(--header-font);
  color: var(--focused-dark);
  font-weight: 300;
  text-align: center;
}

.resource-info {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  column-gap: 2rem;
  justify-content: space-between;
  align-items: center;
}

.resource-item-description {
  width: 100%;
  text-align: justify;
}
@media screen and (min-width: 900px) {
  .resources-body {
    width: 80%;
  }

  .resource-info {
    width: 60%;
    margin: 0 auto;
    flex-direction: row;
    row-gap: 0;
  }
  .resource-item-description {
    width: 70%;
  }
}

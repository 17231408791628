.footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--focused-dark);
}

.footer-info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 2rem 2rem 2rem;
}

.quick-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
}
.quick-links h4 {
  margin: 1rem;
  font-family: var(--header-font);
  font-size: 1.25rem;
  color: var(--focused-white);
  border-bottom: 1px solid var(--focused-white);
}
.quick-links ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.quick-links li {
  text-align: center;
  padding: 0.5rem;
}

.footer-link {
  color: var(--focused-white);
  font-size: 0.875rem;
}
.footer-copywrite {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 300;
  color: var(--focused-white);
  text-align: center;
}

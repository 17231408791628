.footer-cta-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("./title-services-100-2.jpeg");
}

.footer-cta-header {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 2rem;
  color: var(--focused-white);
}
.footer-cta-header h2 {
  text-align: center;
  font-size: 3.5rem;
  font-family: var(--header-font);
  font-style: italic;
  font-weight: 400;
  margin: 0.5rem;
}

.footer-cta {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 2rem 2rem 2rem;
}

.footer-cta form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
}
.footer-cta h3 {
  text-align: center;
  font-weight: 300;
  font-size: 2rem;
  color: var(--focused-white);
  width: 95%;
  padding-bottom: 1rem;
}
.footer-cta input {
  width: 200px;
  text-align: center;
}
